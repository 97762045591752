@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "ETmodules";
  src: url("//db.onlinewebfonts.com/t/9d25ea5c587d0f9d470aa9a3634735ea.eot");
  src: url("//db.onlinewebfonts.com/t/9d25ea5c587d0f9d470aa9a3634735ea.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/9d25ea5c587d0f9d470aa9a3634735ea.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/9d25ea5c587d0f9d470aa9a3634735ea.woff") format("woff"), url("//db.onlinewebfonts.com/t/9d25ea5c587d0f9d470aa9a3634735ea.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/9d25ea5c587d0f9d470aa9a3634735ea.svg#ETmodules") format("svg");
}

body,
html {
  height: 100%;
  margin: 0;
  /* min-width: 1300px !important; */
}

body {
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  color: #091f3b;
  background-color: #f5f5fb;
}

/* Theme colors */

:root {
  --color-primary-main: #033e7a;
  --color-primary-sec: #091F3B;
  --color-primary-third: #4F5C77;
  --color-primary-fourth: #F8F8F8;
  --color-primary-fifth: #f5f5fb;
  --color-primary-sixth: #e6e9eb;
  --color-primary-input: #8DA1B5;
  --color-warn-green: #0EC42B;
  --color-warn-red: #F40000;
  --color-warn-orange: #F1C40F;
  --color-form-button: #2ECC71;
  --color-white: #FFFFFF;
  --color-dark-grey: #C4C4C4;
}

/* Theme colors end */

/* Text section */

.text-primary-light {
  color: #83919e !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.line-height-0 {
  line-height: 0 !important;
}

.line-height-1 {
  line-height: 1em !important;
}

.line-height-2 {
  line-height: 2em !important;
}

.line-height-3 {
  line-height: 3em !important;
}

.line-height-5 {
  line-height: 5em !important;
}

/* Text section */

/* Font section */

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-20 {
  font-size: 20px !important;
}

.font-poppins {
  font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
}

/* Font section end*/

/* margin section */

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 1em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-2 {
  margin-bottom: 2em !important;
}

.mb-3 {
  margin-bottom: 3em !important;
}

.mb-5 {
  margin-bottom: 5em !important;
}

.mr-1 {
  margin-right: 1em !important;
}

.mr-2 {
  margin-right: 2em !important;
}

.mr-3 {
  margin-right: 3em !important;
}

.mr-5 {
  margin-right: 5em !important;
}

.ml-1 {
  margin-left: 1em !important;
}

.ml-2 {
  margin-left: 2em !important;
}

.ml-3 {
  margin-left: 3em !important;
}

.ml-5 {
  margin-left: 5em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-5 {
  margin-top: 5em !important;
}

.pl-3 {
  padding-left: 3em !important;
}

/* margin section end*/

/* padding section start */

.pt-2 {
  padding-top: 2em !important;
}

.p-5 {
  padding: 5em !important;
}

/* Flex Start */

.flex {
  display: flex;
}

.flex.row {
  flex-direction: row;
}

.flex .s-1 {
  flex-grow: 1;
}

.flex .s-2 {
  flex-grow: 2;
}

.flex .s-3 {
  flex-grow: 3;
}

.full-width {
  width: 100%;
}

.max-width {
  max-width: 1110px;
}

/* Flex End */

/* Containers */

.container {
  padding: 4em 4em 0em 4em;
}

/* End containers */

/* Image Start*/

.avatar {
  border-radius: 10px;
}

.avatar.small {
  width: 54px;
  height: 54px;
}

/* Image end */

/* Over riding global */

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 10px !important;
}

.mat-btn-spinner {
  display: inline-block !important;
  margin-right: 5px;
}

.mat-btn-spinner.white circle {
  stroke: #fff;
}

/* Over riding global end*/

/* Page headings */

.page-title-bar {
  width: 100%;
  padding-left: 1.875em;
  padding-right: 1.875em;
}

.page-title {
  font-weight: 600;
  font-size: 1.75em;
  line-height: 2.12em;
  color: #091F3B;
}

.back {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: normal;
  margin-left: 5px;
  color: var(--color-primary-sec)
}

.card {
  background-color: var(--color-white);
  border-radius: 6px;
}

.action-btn-container {
  text-align: center;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-primary-third);
}

.action-card {
  background-color: var(--color-white);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  text-align: center;
}

.bk-white {
  background-color: #fff;
}

.hw-calender {
  background: url("src/assets/icon/calender.svg") no-repeat center;
}

.hw-clock {
  background: url("src/assets/icon/clock.svg") no-repeat center;
}

.hw-timezone {
  background: url("src/assets/icon/time_zone.svg") no-repeat center;
}

.hw-duration {
  background: url("src/assets/icon/duration.svg") no-repeat center;
}

.hw-clock,
.hw-calender,
.hw-timezone,
.hw-duration {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-bottom: -3px;
}

.success-card {
  background-color: var(--color-white);
  border-radius: 6px;
  padding: 13px 24px 13px 14px;
  margin-right: 20px;
  width: 200px;
  font-size: 16px;
  color: var(--color-warn-green);
  line-height: 29px
}

.work-card {
  background-color: var(--color-white);
  border-radius: 0px 10px 10px 10px;
  min-height: 400px;
  min-width: 900px;
  padding: 30px 50px 30px 50px;
}

.list-card {
  background-color: var(--color-white);
  border-radius: 10px;
  height: 70vh;
  min-height: 600px;
  min-width: 900px;
  padding: 10px 30px 50px 30px;
  margin-bottom: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list-card .row {
  min-height: 59px;
  border-bottom: 1px solid #EDEDED;
}

.list-card .row div {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #091F3B;
}

.file-upload {
  background-color: var(--color-dark-grey);
  border-radius: 6px 0px 0px 6px;
  width: 121px;
  min-width: 121px;
  height: 100px;
}

.text-input {
  height: 48px;
  border: 1px solid rgba(184, 202, 232, 0.6);
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  padding: 14px 15px 14px 15px;
  margin-bottom: 30px;
}

::placeholder {
  color: var(--color-primary-input);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-primary-input);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-primary-input);
}

.uploaded-image {
  width: 100%;
  height: 100%;
  border-radius: 6px 0px 0px 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.upload-icon:hover span svg.icon {
  fill: var(--color-primary-third);
}

.c-p {
  cursor: pointer;
}

.has-error {
  color: var(--color-warn-red);
  margin-top: -25px;
  position: absolute;
}

.input-has-error {
  border: 1px solid var(--color-warn-red) !important;
}

/* override base css */

.angular-editor-error div.angular-editor-textarea {
  border: 1px solid var(--color-warn-red) !important;
  margin-bottom: 25px;
}

.angular-editor-toolbar {
  border-radius: 6px 6px 0px 0px !important;
}

.angular-editor-textarea {
  border-radius: 0px 0px 6px 6px !important;
  margin-top: -2px !important;
}

/* override base css end */

.primary-third {
  color: var(--color-primary-third);
}

.small-image {
  width: 48px;
  height: 48px;
  border-radius: 6px;
}

.medium-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.progress-bar {
  height: 8px;
  width: 100px;
  margin-top: 10px;
  border-radius: 21px;
}

.progress-bar .total-count {
  height: 100%;
  width: 100%;
  background: rgba(18, 96, 255, 0.2);
  border-radius: 21px;
}

.progress-bar .filled-count {
  height: 100%;
  background-color: var(--color-primary-main);
  border-radius: 21px;
}

.b-g-t {
  background: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

.line {
  width: 72px;
  height: 2px;
  background-color: var(--color-primary-sec);
  display: block;
  margin-left: 20px;
}

.row-header {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--color-primary-input);
}

.content-div {
  /* background-color: var(--color-primary-fourth); */
}

.button-text {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-primary-third);
}

.button-text:hover {
  color: var(--color-primary-input);
}


.iti {
  box-sizing: border-box;

  .iti__flag-container .dropdown-menu.country-dropdown {
    display: none;
  }

  .iti__flag-container.open .dropdown-menu.country-dropdown {
    display: block;
    inset: 100% auto auto 0px;
    transform: translateY(0px);

    position: absolute;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
  }

  .tooltip {
    display: block;
    pointer-events: none;
    position: absolute;
    z-index: 1070;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;

    & .arrow {
      position: absolute;
      display: block;
      width: .8rem;
      height: .4rem;

      &:before {
        position: absolute;
        content: '';
        border-color: transparent;
        border-style: solid;
      }
    }

    &.show {
      opacity: 0.9;
    }
  }

  .bs-tooltip-top {
    padding: .4rem 0;

    &.tooltip .arrow:before {
      top: 0;
      border-width: .4rem .4rem 0;
      border-top-color: #000;
    }

    & .arrow {
      bottom: 0;
    }
  }

  .tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem;
  }
}